.content {
  width: 75%;
  margin: 0px auto;
}
abbr[title] {

   cursor:pointer !important;
}
.watsapp-icon{
  color: rgb(7, 218, 46);
  font-size:25px;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: none !important;
  --bs-btn-border-color:  none !important;
  --bs-btn-hover-bg:  none !important;
  --bs-btn-hover-border-color:  none !important;
  --bs-btn-active-bg:  none !important;
  --bs-btn-active-border-color:  none !important;
  --bs-btn-disabled-bg:  none !important;
  --bs-btn-focus-shadow-rgb: none !important;
}
.embed-container {
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  /* play with this until right */
  overflow: hidden;
  position: relative;
}

.aboutus-card {
  /* margin-left: 60px !important; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.home-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

.column {
  float: left;
  width: 25% !important;
  padding: 5px;
}

.row {
  margin: 0 -5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.embed-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

body {
  font-family: 'sans-serif', 'Arial Narrow', Arial, ;
  overflow-x: hidden;
}

.image-align-center {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}
.butto_center{
  margin-left: auto !important;
  margin-right: auto !important;
  /* width: 25% !important; */
  /* margin-left: 10px !important; */
}
.product-heading{
  text-align: center;
  font-size: 35px;
}
.home-quote {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.home-descroption {
  font-size: 20px;
  text-align: justify;
}

.nav-dropitem-align {
  margin: 0px !important;
  padding: 0px !important;
}

.nav-content-item {
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: white !important;
  box-shadow: 0px 5px 5px #cdcaca;
  align-items: center;
  width: 100%;
  height: 90px;
  /* animation: nav-load 300ms ease-in; */
  /* padding: 2px !important; */
}

h1,h3,h4,h5,h6 {
  color: rgb(155, 35, 14);
}
.footer-link-center{
 text-align: center !important;
}
.navlink-items {
  padding-left: 30px;
  padding-top:15px;
  color: #282c34 !important;
  text-decoration: none;
  /* --bs-nav-link-padding-y: 0.5rem; */
}

.right-nav-link, .footer-icon {
  font-size: 15px !important;
  color: rgb(155, 35, 14) !important;
}
.nav-icon{
 padding-right:8px  !important;
}
.dropdown-menu {
  padding: 0px !important;
  --bs-dropdown-link-active-bg: none !important;
  --bs-dropdown-link-active-color: none !important;
  --bs-dropdown-link-hover-bg: none !important
}

.nav-link {
  color: #282c34 !important;
}
.foter-float{
  float: right;
}
.footer {
  background-color: white;
  color: black;
  width: 100%;
  /* padding-bottom: 30px; */
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-link:hover {
  color: rgb(155, 35, 14);
}
.nav-media-link{
  color:rgb(155, 35, 14) ;
  text-decoration: none;
}
.media-link{
  color:rgb(155, 35, 14) ;
  text-decoration: none;
}
.media-link:hover{
  color:grey ;
}
.footer-content {
  width: 600px;
  list-style-type: none;
  margin: 20px;
  width: 100%;
}
.dropdown-btn {
  width: auto !important;
}

.dropdown-menu[data-bs-popper] {
  width: 450px;
  font-size: 15px;
  font-weight: 500;
  padding: 15px !important;
  border: 2px solid grey;
  margin-top: 20px !important;
}

.dropdown-menu[data-bs-popper]:hover {
  box-shadow: none;
}

.dropitem {
  border: none;
  text-decoration: none;
  font-weight: 400 !important;
  display: block !important;
  /* padding: 10px !important; */
  padding: none !important;
}

.dropitem:hover {
  color: rgb(155, 35, 14) !important;
}

.aboutus-p {
  font-size: 20px;
}

.para-align {
  text-align: justify;
}

.aboutus-row {
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  /* padding-left:100px; */
  color: white !important;
  padding: 10px;
}

.aboutus-col {
  padding: 10px;
  border: 1px solid rgb(155, 35, 14);
  border-radius: 8px;
  width: 90%;
  background-color: white;
  align-items: center;
  color: rgb(155, 35, 14) !important;
  box-shadow: 1px 1px 1px 1px rgb(155, 35, 14);
  /* animation: nav-load 500ms ease-in; */
}

.about-us-card {
  width: 20rem;
  height: 400px;
}

.logo {
  width: 150px;
  height: 80px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.footer-log {
  width: 150px;
  height: 80px;

}

.pcard-style {
  width: 20rem;
}

.footer-logo-padding {
  padding-left: 10px !important;
}

.contact-row {
  /* text-align:center; */
  margin: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-li {
  font-size: 25px;
  color: purple;

}

.contact-i {
  color: blue;
  font-size: 30px;
}
.contact-button{
  margin-left: 10px;
}
.contact-button,
.aboutus-button ,.download-button{
  background-color: rgb(155, 35, 14) !important;
  border: 1px solid rgb(155, 35, 14) !important;
}

.contact-button:hover {
  box-shadow: 1px rgb(140, 133, 133) !important;
}
.download-button:hover{
  color:white!important;
}
.aboutus-button-card {
  color: black;
  background-color: white !important;
  width: auto;
  border: none !important;
  margin-top: 2px !important;
  font-size: 20px;
}

.nav-item-aniamtion {
  animation: nav-link-load;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
  transform: scale(0);
}
.product-hover:hover {
  box-shadow: 5px 5px 10px 5px grey;
}

.product-pdf {
  width: "100%" !important;
  margin: auto;
  float: none;
}

element.style {
  display: block;
  background-color: white;
  overflow: hidden;
  /* width: 841.89px; */
  /* height: 595.276px; */
  width: 100% !important;
  user-select: none;
}

.nav-item-hover:hover {
  /* box-shadow: 10px 5px 5px rgb(168, 104, 93); */
  color: rgb(155, 35, 14) !important;
}

.home-link {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.home-link:hover {
  color: white;
}

.carousel-heigth {
  height: 550px;
}

.hr-style-one {
  border: 0;
  height: 3px;
  background: rgb(13, 12, 12);
  width: 100%;
  /* background-image: linear-gradient(to right, #ccc, #333, #ccc); */
}

.facility-para {
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 18px;
}

.facility-image {
  width: 100%;
  height:auto;
}

.center-item {
  margin: auto !important;
  float: none !important;
}
.react-pdf__Document {
  border-radius: 8px;
  max-width: 100%;
  position: relative;
  background-color: white;
}
.react-pdf__Page {
  max-width: 400px;
  width: 90vw;
}
.react-pdf__Page__textContent {
  position: absolute;
  text-align: initial;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto !important;
  height: auto !important;
  overflow: hidden;
  line-height: 1;
  text-size-adjust: none;
}
.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
}
@keyframes nav-load {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes nav-link-load {
  0% {
    transform: scale(0);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 600px) {
  .contact-button{
    margin-left: 23px;
  }
  .butto_center{
    margin-left: auto !important;
    margin-right: auto !important;
    /* width: 25% !important; */
    /* margin-left: 20px !important; */
  }
  .right-nav{
    display: flex;
  }
  .nav-media-link{
    color:white ;
    text-decoration: none;
  }
  .right-nav-link {
    font-size: 12px !important;
    color: white !important;
  }
  .foter-float{
    float: left;
  }

  .about-us-card {
    width: 22rem;
    height: 400px;
  }

  .aboutus-card {
    margin-left: 25px !important;
  }

  .carousel-heigth {
    height: 300px;
  }

  .product-hover {
    margin: auto;
    margin-bottom: 7px;
  }

  .pcard-style {
    width: 22rem !important;
  }

  .column {
    width: 25%;
    display: block;
    margin-bottom: 20px;
  }

  .navbar-nav {
    -bs-nav-link-padding-y: 0px !important;
  }

  .collapse-nav {
    background-color: rgb(155, 35, 14) !important;
    color: white !important;
    z-index: 1 !important;
    /* margin-left: 630px;
    float: left !important;
    padding-top: 10px;
    padding-left: 10px; */
    /* display: flex; */
    padding: 20px;
  }

  .navbar-toggler {
    margin: 10px;

  }

  .nav-content-item {
    box-shadow: 5px 5px 5px #cdcaca;
    color: white !important;
    height: auto;
  }

  .navlink-items {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px !important;
    color: white !important;
    text-decoration: none;
    height: 50px;
    /* --bs-nav-link-padding-y: 0.5rem; */
  }

  .footer-logo-padding {
    /* padding-left: 10px !important; */
    --bs-gutter-x: 1.5rem !important;
  }

  .aboutus-col {
    margin-bottom: 20px;
    --bs-gutter-x: 1.5rem !important;
  }
  .navbar-collapse-item {
    padding-left: 20px;
  }
  .nav-item-hide {
    visibility: hidden;
  }
  .dropdown-menu[data-bs-popper] {
    width: auto;
    font-size: 15px;
    font-weight: 500;
    width: 300px;
  }
  .navlink-items {
    padding-top: 0px !important;
  }
  .collapse-dropdown-menu {
    padding-left: 0px !important;
  }
  .dropdown-color {
    color: white !important;
  }

  .navbar {

    --bs-navbar-active-color: white;
  }

  .nav-link {
    color: white !important;
    display: revert !important;
  }

  .dropitem {
    display: inline !important;
  }

  img,
  svg {
    width: 100%;
    height: 300px;
  }

  .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
  }

  .card-row {
    --bs-gutter-x: 0rem !important;
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {

  .tab-nav{
    padding-left: 10px !important;
  }
  .right-nav-link {
    font-size: 12px !important;
    color: rgb(155, 35, 14) !important;
  }

  .nav-link {
    /* color: white !important; */
    display: revert !important;
  }

  .about-us-card {
    width: 20rem;
    height: 400px !important;
  }

  .navbar-toggler {
    margin: 10px;

  }

  .aboutus-card {
    margin-left: 0px !important;
    margin-right: auto !important;
  }

  .react-pdf__Page {
    max-width: 800px !important;
    width: 90vw;
  }

  .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
  }

  img,
  svg {
    width: 100%;
    height: 500px;
  }

  .carousel-heigth {
    height: 450px;
  }

  .row {
    justify-content: space-around;
  }
  .product-hover {
    margin: auto;
    margin-bottom: 7px;
  }

  .column {
    width: 50%;
    display: block;
    margin-bottom: 20px;
  }

  .dropitem {
    display: inline !important;
  }

  .navbar-nav {
    -bs-nav-link-padding-y: 0px !important;
  }

  .footer-logo-padding {
    padding-left: 10px !important;
  }

  .nav-content-item {
    box-shadow: 5px 5px 5px #cdcaca;
    height: auto;
    font-size: 15px !important;
  }
  .aboutus-col {
    margin-bottom: 20px;

  }
  .navlink-items {
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 15px !important;
    text-decoration: none;
    height: 50px;
  }
  .dropdown-menu[data-bs-popper] {
    width: 450px;
  }
  .card-align-2-row {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .navbar {

    --bs-navbar-active-color: white;
  }
}

